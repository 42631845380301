<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div class="steps">
          <el-steps :active="active" finish-status="success" align-center>
            <el-step title="邮箱验证"></el-step>
            <el-step title="重置交易密码"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </div>
        <el-row class="steps" v-if="active === 0">
          <el-col :span="14">
            <el-form :model="emailForm" :rules="emailRules" ref="emailForm" label-width="400px">
              <el-form-item label="邮箱" prop="email">
                <el-input type="email" v-model="emailForm.email" disabled>
                  <el-button slot="append" :loading="codeLoading" @click.stop="handleSendCode"
                             :disabled="emailCodeTimeLen !== 60">{{
                      emailCodeBtnText
                    }}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <el-input v-model="emailForm.code"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleEmailNext" :loading="emailNextBtnLoading">下一步</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row class="steps" v-if="active === 1">
          <el-col :span="14">
            <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="400px">
              <el-form-item label="密码" prop="dealPassword">
                <el-input type="password" v-model="passwordForm.dealPassword"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmDealPassword">
                <el-input type="password" v-model="passwordForm.confirmDealPassword"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handlePasswordNext" :loading="passwordNextBtnLoading">下一步</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row class="steps" v-if="active === 3" style="text-align: center">
          <el-col>
            <el-result icon="success" title="交易密码重置成功">
              <template slot="extra">
                <el-button plain @click="handleGoBack">返回</el-button>
              </template>
            </el-result>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>

import { AccountApi, SysOrgApi } from '@/api'
import { dealPasswordValidator } from '@/utils/validator'

export default {
  name: 'DealPasswordReset',
  data () {
    return {
      emailForm: {
        email: '',
        code: ''
      },
      emailRules: {
        email: [
          {
            required: true,
            message: '请输入邮箱地址',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      },
      emailNextBtnLoading: false,
      emailCodeBtnText: '发送验证码',
      emailCodeTimer: null,
      emailCodeTimeLen: 60,
      active: 0,
      currentOrg: {},
      passwordForm: {
        dealPassword: '',
        confirmDealPassword: ''
      },
      passwordRules: {
        dealPassword: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: dealPasswordValidator
          }
        ],
        confirmDealPassword: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: dealPasswordValidator
          }
        ]
      },
      passwordNextBtnLoading: false,
      codeLoading: false
    }
  },
  methods: {
    initData () {
      SysOrgApi.getCurrent().then(res => {
        this.currentOrg = res
        this.emailForm.email = res.contactsEmail
      })
    },
    handleSendCode () {
      this.codeLoading = true
      AccountApi.sendEmailCode().then(() => {
        this.$message.success('发送成功')
        this.codeLoading = false
        this.renderCodeBtn()
      }).catch(() => {
        this.codeLoading = false
      })
    },
    renderCodeBtn () {
      this.emailCodeTimer = setInterval(() => {
        if (this.emailCodeTimeLen <= 0) {
          this.emailCodeTimeLen = 60
          this.emailCodeBtnText = '发送验证码'
          clearInterval(this.emailCodeTimer)
          this.emailCodeTimer = null
        } else {
          this.emailCodeTimeLen = this.emailCodeTimeLen - 1
          this.emailCodeBtnText = `${this.emailCodeTimeLen}秒后重新发送`
        }
      }, 1000)
    },
    handleEmailNext () {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.emailNextBtnLoading = true
          AccountApi.validEmailCode(this.emailForm).then(() => {
            this.$message.success('验证成功')
            this.emailNextBtnLoading = false
            this.active++
          }).catch(() => {
            this.emailNextBtnLoading = false
          })
        } else {
          return false
        }
      })
    },
    handlePasswordNext () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.passwordNextBtnLoading = true
          AccountApi.saveDealPassword(this.passwordForm).then(() => {
            this.passwordNextBtnLoading = false
            this.active += 2
          }).catch(() => {
            this.passwordNextBtnLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleGoBack () {
      this.$router.back()
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style scoped lang="less">

.steps {
  margin: 20px 0 100px 0;
}

</style>
