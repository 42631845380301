/**
 * 交易密码验证
 * @param rule
 * @param value
 * @param callback
 */
export function dealPasswordValidator (rule, value, callback) {
  if (!value) {
    callback(new Error('请输入密码'))
  } else if (/^\d{6}$/.test(value) === false) {
    callback(new Error('请设置6位数字密码'))
  } else {
    callback()
  }
}

/**
 * 交易密码验证
 * @param rule
 * @param value
 * @param callback
 */
export function moneyValidator (rule, value, callback) {
  if (!value) {
    callback(new Error('请输入金额'))
  } else if (/(^[1-9]\d*$)/.test(value) === false) {
    callback(new Error('请输入正确的金额'))
  } else {
    callback()
  }
}
